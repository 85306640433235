import React from "react"
// import { Link, graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import ListGroup from "react-bootstrap/ListGroup"

import Layout from "../../components/layout/basic"
import SEO from "../../components/global/SEO"

const DopomohaPage = () => {
  // const data = useStaticQuery(graphql`
  //   {
  //     callimg: contentfulAsset(
  //       id: { eq: "0491ac7c-2138-51fe-b644-c895e4cdfcc0" }
  //     ) {
  //       fluid(
  //         maxWidth: 1200
  //         maxHeight: 600
  //         resizingBehavior: FILL
  //         cropFocus: FACE
  //         quality: 100
  //       ) {
  //         ...GatsbyContentfulFluid_withWebp
  //       }
  //       title
  //     }
  //   }
  // `)

  return (
    <Layout>
      <SEO
        title="Допомога Нового Телекому"
        description="Підтримка Нового Телекому"
        image=""
        url="/dopomoha/"
      />
      <div className="hero-background-light">
        <Container className="py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Newtelecom</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Допомога
              </li>
            </ol>
          </nav>
          <h1 className="gradient-text">Допомога</h1>
          <div className="hero-slogan py-4">
            Сторінка в розробці.
            <br /> Якщо потрібна допомога і Ви не знайшли її на сайті,
            зверніться за телефонами підтримки, будь ласка.
          </div>
        </Container>
      </div>
      <Container className="my-5">
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Підключення</Card.Title>
                <Card.Text>content here</Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush" variant="flush">
                <ListGroup.Item>
                  Чи можливо змінити тарифний план?
                </ListGroup.Item>
                <ListGroup.Item>
                  Як налаштувати декілька пристроїв?
                </ListGroup.Item>
                <ListGroup.Item>
                  Чи прокладається кабель по квартирі?
                </ListGroup.Item>
                <ListGroup.Item>
                  Чи можливо призупинити Інтернет?
                </ListGroup.Item>
                <ListGroup.Item>
                  Як підключитися до мережі Newtelecom?
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Оплата</Card.Title>
                <Card.Text>content here</Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush" variant="flush">
                <ListGroup.Item>
                  Як перевірити залишок на особистому рахунку?
                </ListGroup.Item>
                <ListGroup.Item>
                  Як перевірити залишок на особистому рахунку?
                </ListGroup.Item>
                <ListGroup.Item>
                  Що зробити, якщо після оплати не включився інтенет?
                </ListGroup.Item>
                <ListGroup.Item>Кредитний період</ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Інструкції</Card.Title>
                <Card.Text>content here</Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroup.Item>Особистий кабінет</ListGroup.Item>
                <ListGroup.Item>
                  Як під'єднати декілька пристроїв у квартирі?
                </ListGroup.Item>
                <ListGroup.Item>Чи обмежуються порти?</ListGroup.Item>
                <ListGroup.Item>
                  Що зробити, якщо не працює інтернет?
                </ListGroup.Item>
                <ListGroup.Item>
                  Що зробити, якщо не працює інтернет?
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="my-5">
        <h2>Допомога та відповіді на запитання</h2>
        <h3>Налаштування мережі NewTelecom</h3>
        <p>
          Компанія NewTelecom завжди піклується про своїх клієнтів і рухається в
          ногу з часом, удосконалюючи свої технології відповідно до світових
          стандартів телекомунікаційного зв’язку. Компанія впровадила для Вас
          доступ до мережі Інтернет через технологію DHCP, яка дозволить Вам
          швидко відновлювати зв’язок після переналаштування програмного
          забезпечення та операційної системи.
        </p>
        <h3>Як замінити тарифний план? </h3>
        <p>
          Зміна тарифного плану проводиться в особистому кабінеті. Нові умови
          роботи почнуть діяти з 1-го числа наступного місяця. Актуальні умови
          переходу Ви завжди можете подивитися в розділі Тарифи. Перехід на
          новий варіант роботи при мінусовому депозиті не відбувається. Тільки
          після погашення заборгованості Ви можете перейти на новий тарифний
          план.
        </p>
        <h3>Як перевірити залишок на особистому рахунку?</h3>
        <p>
          Щоб перевірити Ваш залишок на депозиті, необхідно зайти в “Особистий
          кабінет”. У розділі “Персональні дані”, у другому рядку зазначено
          Депозит: сума, яка залишилася на особистому рахунку в день перегляду.
          Детальну інформацію про зняття коштів щодня можна подивитися в розділі
          “Управління рахунком”.
        </p>
        <h3>Чи можливо призупинити Інтернет?</h3>
        <p>
          Так. Абонент може призупинити доступ до мережі Інтернет:
          Зателефонувавши в офіс до абонентського відділу, узгодити з ними дату
          призупинення, але на строк не більше ніж на 2 місяці.
          <br />* Призупинення послуг на строк більше ніж 2 місяці вимагає
          подальшої платної активації.
        </p>
        <p>
          Компанія NewTelecom пропонує своїм абонентам декілька варіантів оплати
          Інтернет-послуг:
        </p>
        <h4>1. Оплата через Інтернет</h4>
        <p>
          Через платіжні системи. За допомогою платіжних карток Visa, Visa
          Electron, MasterCard.
          <br />
          Зручність полягає в тому, що Вам не потрібно нікуди йти: ви можете
          перерахувати гроші, не виходячи з дому.
          <br />
          Комісія за перерахування грошових коштів – 0%.
          <br /> Доступ до особистого кабінету користувача можливий через форму
          на сайті. Вводимо логін і пароль, зазначені у Реєстраційній картці
          користувача, яка була надана при підключенні.
        </p>
        <h4>2. Оплата через термінал</h4>
        <p>Ми працюємо з наступними операторами електронних платежів:</p>
        <ul>
          <li>– IBox</li>
          <li>– 24 NON STOP</li>
          <li>– Easy Pay</li>
          <li>– 24 Money</li>
          <li>– Банк 24 Національний кредит (ПлатЁжка)</li>
        </ul>

        <p>
          Комісія складає 2 грн. Оплата здійснюється через ваш Логін, який
          вказаний в реєстраційній картці абонента. Перед внесенням коштів
          перевіряйте ваші П.І.Б. на екрані терміналу.{" "}
        </p>

        <h3>3. Оплата через банк</h3>

        <p>Платежі можна здійснити в будь-якому відділенні банку.</p>
        <h4>Реквізити ТОВ “Новий Телеком”</h4>
        <p>
          Установа банку: АТ КБ “ПриватБанк” у м. Києві
          <br />
          Рахунок: UA81 3052 9900 0002 6005 0150 2950 2;
          <br />
          МФО: 305299; ЗКПО: 37866847.
        </p>
        <p>
          * Будьте уважні при заповненні квитанції, правильно вказуйте номер
          рахунку-фактури, Логін і ПІБ. Якщо платіж був здійснений з помилковими
          даними, то внесення коштів на особовий рахунок абонента не
          відбувається. Цей платіж залишається на розрахунковому рахунку
          NewTelecom до моменту ідентифікації відправника.
        </p>
        <p>
          Дуже просимо: для того, щоб запобігти відключення від мережі
          NewTelecom через заборгованість, радимо нашим абонентам самостійно
          контролювати стан особового балансу і завчасно сплачувати послуги
          Інтернет.
        </p>
        <h2>Підключення</h2>
        <h3>Чи прокладається кабель по квартирі?</h3>
        <p>
          Монтаж кабелю по квартирі абонента не входить у вартість підключення.
          Якщо виникла велика необхідність в цьому, то монтаж можна узгодити з
          працівниками при підключенні. У вартість підключення входить:
        </p>
        <p>
          – монтаж кабелю до квартири абонента; – проведення процесу реєстрації
          обладнання Замовника в мережі; – надання до 20 метрів кабелю для
          самостійного монтажу.{" "}
        </p>
        <p>Як здійснюється підключення до мережі Newtelecom? </p>
        <p>
          Для початку, необхідно, щоб будинок був підключений до мережі
          NewTelecom, перевірити це можливо зателефонувавши в офіс компанії за
          номером: (044) 277 4 277 для отримання детальної інформації.
        </p>
        <p>
          Компанія здійснює якісний монтаж кабелю індивідуально. Зверніть увагу
          на те, що наші співробітники підключають до мережі інтернет один
          комп’ютер. Далі ми проводимо процес реєстрації обладнання абонента в
          нашій мережі (перевірка цілісності лінії та налаштування мережевої
          карти комп’ютера).
        </p>
        <p>
          Виконавши налаштування на комп’ютері абонента, співробітники компанії
          проводять тестування доступу до мережі Інтернет.
        </p>
        <p>
          Договір та реєстраційну картку з параметрами доступу до мережі
          інтернет наші співробітники залишають абоненту.
        </p>
        <p>Протягом якого терміну відбувається підключення?</p>
        <p>
          Термін підключення становить до 10 робочих днів з моменту підписання
          Договору та зарахування Вашої оплати. Більш конкретну інформацію Вам
          може надати менеджер абонентського відділу на момент підписання
          Договору. На момент отримання заявки, з Вами зв’яжеться оператор
          відділу підключень і домовиться про день і час.
        </p>
        <p>
          Існує також прискорене підключення – це додаткова послуга, яка
          оплачується окремо і складає 100 грн (одноразово). У цьому випадку
          підключення відбувається протягом доби, поза чергою.
        </p>
        <p>Як під'єднати декілька пристроїв у квартирі?</p>
        <p>
          Вам необхідно придбати додаткове устаткування – WI-FI роутер, або
          замовити його у нашому офісі.
        </p>
        <p> Налаштувати роутер Ви зможете:</p>
        <p>
          – самостійно, використовуючи параметри доступу, зазначені у вашій
          реєстраційній картці, яку видають при підключенні; – скориставшись
          додатковою послугою по налаштуванню роутера в офісі або у вас.
        </p>
        <p>
          * Наша компанія обладнання в оренду домашнім користувачам не надає.
        </p>
        <p>Чи можливе подання заявки на підключення, не приходячи в офіс?</p>
        <p>
          Так. Для цього потрібно зателефонувати в наш в офіс або залишити
          заявку на сайті. Для Вас сформують договір та рахунок на підключення.
        </p>
        <p>Часто запитують</p>
        <p>Чи обмежуються порти? </p>
        <p>
          Список обмежень при роботі з реальною IP-адресою в мережі Newtelecom:
          smtp 25 tcp – запобігання розсилки спаму через відкриті релеї (в
          абонентів) domain 53 tcp / udp – запобігання DNS флуду через відкриті
          DNS (в абонентів) web 80 tcp – захист абонентських роутерів.
        </p>
        <p>Послідовність дій, якщо не працює інтернет </p>
        <p>
          Якщо у Вас проблеми з доступом до Мережі або пропав інтернет,
          повідомте, будь ласка, про це у відділ технічної підтримки абонентів
          за тел. (044) 277 4 277. Наш технічний персонал виявить причину
          проблеми та усуне її.
        </p>
        <p>Де 100 Мбіт?</p>
        <p>
          {" "}
          Швидкість передачі даних Fast Ethernet, рівну 100 Мбіт/с, не можна
          інтерпретувати як реальну пропускну здатність. Вдалий показник, який
          міг би характеризувати Fast Ethernet – це максимально можлива
          продуктивність, виміряна в байтах в секунду. На жаль, її не можна
          обчислити, виходячи лише зі значення швидкості в 100 Мбіт / с Fast
          Ethernet, так само як і всі інші технології ЛВС, не є стовідсотково
          ефективними, тому що з передачею даних пов’язані накладні витрати.
        </p>
        <p>
          Максимальну пропускну спроможність можна обчислити з максимального
          числа пакетів, які можна передати за одну секунду і максимального
          розміру корисних даних в кожному пакеті. Загальний розмір пакета
          становить 12304 байт, куди входить 12 000 бітів корисних даних, 304
          байт службової інформації. Розділивши 100000000 бітів/с на 12304 біт,
          отримаємо максимальну швидкість передачі 8127,44 пакета максимального
          розміру.{" "}
        </p>
        <p>
          В кожному пакеті 12000 бітів (1500 байт) корисних даних, то теоретично
          максимальна продуктивність Fast Ethernet дорівнює 12191157 байтів/сек
          12,2 Мб/с.{" "}
        </p>
        <p>
          Однак, не всі передані пакети мають максимальний розмір. Вузлу часто
          необхідно передавати дані, обсяг яких не перевищує 1500 байтів. Тому
          розрахуємо пропускну здатність для пакетів з іншими розмірами корисних
          даних.{" "}
        </p>
        <p>
          Так для мінімального розміру даних в пакеті 46 байтів (368 бітів),
          розмір пакету складе 368 + 304 = 672 біти, і передаваних пакетів в сек
          буде 100000000/672 = 148809,52. І пропускна спроможність 148809,52 *
          46 = 6845238 байтів в сек.{" "}
        </p>
        <p>
          Складемо таблицю залежності пропускної спроможності від корисних
          даних.
        </p>
        <p>
          Корисні дані, байти Макс. число пакетів, що передаються в 1 сек
          Пропускна здатність, Мбайт / с: 1500 8127,44 12,19 1262 9615,38 12,13
          1006 11973 12,04 238 45289,86 10,77 46 148809,52 6,85 16 148809,52
          2,38 3 148809,52 0,446{" "}
        </p>
        <p>
          Зверніть увагу, що швидка передача пакетів маленького розміру –
          найменш ефективний метод пересилання даних. Наприклад, статистика
          одного з портів комутатора домашньої мережі така:
        </p>
        <p>
          {" "}
          64 Bytes Frames 1283194530 65-127 Bytes Frames 540396115 128-255 Bytes
          Frames 457870686 256-511 Bytes Frames 308805218 512-1023 Bytes Frames
          658469528 1024-1518 Bytes Frames 1840737894{" "}
        </p>
        <p>Рекомендації щодо перевірки швидкості </p>
        <p>
          Якщо у Вас виникли сумніви щодо швидкості передачі, Ви можете виконати
          наступні рекомендації:{" "}
        </p>
        <p>
          1. Кабель повинен бути підключений до комп’ютера. Якщо це так, тоді
          потрібно перейти до вимірів швидкості, звантаження файлів з
          нижчеперелічених посилань.
        </p>
        <p>
          {" "}
          2. Якщо використовується роутер, то необхідно під'єднати з нього
          кабель безпосередньо до комп’ютера.{" "}
        </p>
        <p>
          Виміри швидкості здійснюються таким чином: спробуйте звантажити
          великий файл:
        </p>
        <p>
          ftp://ftp.linux.org.ua/pub/Linux/ASPLinux/i386/14/ASPLinux-14-i386-DVD.iso
        </p>
        <p>
          – показники швидкості по цьому файлу покажуть швидкість закачування
          Українського сегменту;
        </p>
        <p>
          {" "}
          Ці ресурси рекомендовані нами, як стабільні у швидкості і якості
          передачі. Але необхідно пам’ятати про те, що швидкість – динамічно
          змінюваний параметр і при доступі до одного і того ж ресурсу в різний
          час може бути різною, а також браузери показують швидкість
          завантаження в кілобайтах на секунду, що вимагає перекладу в Мбіт/сек.
        </p>
        <p> Наприклад: 1000 Кбайт/сек. * 8 = 8Мбіт/сек. </p>
        <p>
          При виникненні технічних запитань, відправте їх на електронну пошту
          технічного відділу support@newtele.com.ua для подальшого аналізу та
          пояснення
        </p>
      </Container>
    </Layout>
  )
}

export default DopomohaPage
